<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <LogoImage />
      </v-col>
      <v-col
        cols="12"
        align="center"
        class="white--text"
      >
        sign up
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogoImage from '../_elements/logo/LogoImage'

export default {
  components: {
    LogoImage
  }
}
</script>

<style lang="scss" scoped></style>
